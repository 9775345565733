import React, { FunctionComponent, ReactElement } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import History from './BrowserHistory';
import { RouteWrapper } from './RouteWrapper';
import { BasicLayout } from '../layout/BasicLayout';
import { HomeView } from '../views/HomeView';

/**
 * The routes component.
 *
 * @returns {ReactElement} Router.
 */
export const Routes: FunctionComponent = (): ReactElement => {
    return (
        <Router history={History}>
            <Switch>
                <RouteWrapper exact path="/" layout={BasicLayout}>
                    <HomeView />
                </RouteWrapper>
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};
