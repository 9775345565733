import { ComponentsStyles, IFontStyles, IPalette, IRawStyle } from '@fluentui/react';

/**
 * The color palette for the theme.
 */
export const Palette: Partial<IPalette> = {
    themePrimary: '#1893a3',
    themeLighterAlt: '#f3fafb',
    themeLighter: '#d0ecf0',
    themeLight: '#a9dde3',
    themeTertiary: '#62bcc8',
    themeSecondary: '#2c9fae',
    themeDarkAlt: '#168493',
    themeDark: '#13707c',
    themeDarker: '#0e525b',
    neutralLighterAlt: '#fa0',
    neutralLighter: '#52c31d',
    neutralLight: '#A07902', //<--border
    neutralQuaternaryAlt: '#333', // <--border-top
    neutralQuaternary: '#ff0',
    neutralTertiaryAlt: '#3e5e75',
    neutralTertiary: '#707070',
    neutralSecondary: '#8c8c8c',
    neutralPrimaryAlt: '#707070',
    neutralPrimary: '#E6E6D2',
    neutralDark: '#c4bfb7',
    black: '#faf9f8',
    white: '#000',
    red: '#B23030',
};

/**
 * The fonts for the theme.
 */
export const Fonts: Partial<IFontStyles> = {
    // Control Icons
    small: {
        fontSize: '12px',
    },
    // Nav Menus, Labels
    medium: {
        fontSize: '14px',
    },
    // Icons
    mediumPlus: {
        fontSize: '16px',
    },
    // Modal Headlines
    large: {
        fontSize: '20px',
        fontWeight: 'regular',
    },
    // Main Headlines
    xxLarge: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
};

/**
 * The default font style to use for theme.
 */
export const DefaultFontStyle: IRawStyle = {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: 'regular',
};

/**
 * Default styles for fluent ui components.
 *
 * @param {IPalette} palette The theme color palette.
 * @returns {ComponentsStyles} The default component styles.
 */
export const DefaultComponentStyles = (palette: Partial<IPalette>): ComponentsStyles => {
    return {
        MessageBar: {
            styles: {
                root: {
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                },
                innerText: {
                    paddingTop: 2,
                },
            },
        },
        DefaultButton: {
            styles: {
                root: {
                    borderRadius: 2,
                    color: palette.themeSecondary,
                    borderColor: palette.themeSecondary,
                },
                rootHovered: {
                    color: palette.themeSecondary,
                },
                rootPressed: {
                    color: palette.themeDarkAlt,
                },
            },
        },
    };
};
