import { IMessageBarStyles, MessageBar, useTheme } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useStoreActions, useStoreState } from '../store/Store';
import { IThemed } from '../types/IThemed';

const LayoutContainer = styled.div`
    display: flex;
    flex: 1;
    max-height: calc(100% - 56px);
`;

const ContentContainer = styled.div<IThemed>`
    display: flex;
    flex: 1;
    background-color: #282822;
    border-radius: 6px;
    border-color: ${(props) => props.palette.neutralLight};
    border-width: 1px;
    border-style: solid;
    overflow: auto;
`;

const HeaderContainer = styled.div<IThemed>`
    display: flex;
    align-items: center;
    padding: 6px;
    background-color: #151515;
    min-height: 44px;
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.palette.neutralQuaternaryAlt};
`;

const LogoImage = styled.img``;

const LayoutContainerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ContentWrapper = styled.div<IThemed>`
    display: flex;
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    min-height: 300px;
    max-width: 485px;
    padding: 24px;
    @media (max-width: 768px) {
        max-height: 500px;
    }
    margin: auto;
`;

export interface LayoutProps {
    /** Child component. */
    children?: ReactNode;
}

/**
 * The basic layout component.
 *
 * @param {LayoutProps} props Layout properties.
 * @returns {ReactElement} Basic layout.
 */
export const BasicLayout: FunctionComponent<LayoutProps> = (props: LayoutProps): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    /**
     * Store state of the global notification message bar.
     */
    const notificationModel = useStoreState((state) => state.GlobalNotificationModel.notification);

    /**
     * Store action to update the global notification message bar.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * Whether the global message bar is visible or not.
     */
    const [isMessageBarVisible, setIsMessageBarVisible] = useState<boolean>(false);

    /**
     * Global message bar style.
     */
    const messageBarStyles: IMessageBarStyles = {
        root: { width: 'auto', minWidth: 400, margin: 'auto', bottom: 20, left: '50%', transform: 'translateX(-50%)', position: 'absolute', display: 'flex', zIndex: 99999 },
    };

    /**
     * Displays a new notification model and hides it after a timeout.
     */
    useEffect(() => {
        let messageBarTimeout: number | undefined;
        if (notificationModel) {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
            messageBarTimeout = window.setTimeout(() => {
                setIsMessageBarVisible(false);
                updateNotification(undefined);
            }, 5000 + Math.ceil(notificationModel.message.length / 120) * 1000);
            setIsMessageBarVisible(true);
        }
        return () => {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
        };
    }, [isMessageBarVisible, notificationModel, updateNotification]);
    return (
        <LayoutContainerWrapper>
            <HeaderContainer palette={theme.palette}>
                <LogoImage src="./logo.svg" alt="" width={28} height={28} />
            </HeaderContainer>
            <LayoutContainer>
                <ContentWrapper palette={theme.palette}>
                    <ContentContainer palette={theme.palette}>{props.children}</ContentContainer>
                </ContentWrapper>
            </LayoutContainer>
            {notificationModel && isMessageBarVisible && (
                <MessageBar styles={messageBarStyles} messageBarType={notificationModel.type}>
                    {notificationModel.message}
                </MessageBar>
            )}
        </LayoutContainerWrapper>
    );
};
