import React, { FunctionComponent, ReactElement } from 'react';
import { IStackTokens, Stack, Text } from '@fluentui/react';
import styled from 'styled-components/macro';

const Container = styled.div`
    padding: 24px 35px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
`;

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const HomeView: FunctionComponent = (): ReactElement => {
    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 28,
    };

    return (
        <Container>
            <Stack tokens={stackTokens} verticalAlign="center">
                <Text variant={'xxLarge'}>{'Hallo World !'}</Text>
                <Text style={{ marginLeft: 'auto', marginRight: 'auto' }} variant={'medium'}>{`How you doin' ?`}</Text>
            </Stack>
        </Container>
    );
};
