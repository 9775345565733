import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { i18n } from 'i18next';
import styled from 'styled-components/macro';
import { Routes } from './navigation/Routes';
import { PublicClientApplication } from '@azure/msal-browser';
import { _config } from '.';
import { I18nextProvider } from 'react-i18next';
import { initialize } from './helper/localization/Localization';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { configureAi, reactPlugin } from './helper/AiHelper';

const AppContainer = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
    min-width: 100%;
    width: 100%;
`;

/**
 * The msal instance to use through the application.
 */
export let msalInstance: PublicClientApplication;

/**
 * The application component.
 *
 * @returns {ReactElement | null} Main App component or NULL if a dependency is missing.
 */
const App: FunctionComponent = (): ReactElement | null => {
    /** The state of the i18n instance. */
    const [i18nInstance, setI18nInstance] = useState<i18n>();

    /**
     * Initialize the application.
     */
    useEffect(() => {
        _config.then((configuration) => {
            if (!configuration) {
                console.error('Configuration could not be loaded.');
                return;
            }
            // Configure Application Insights
            if (configuration.uiOptions && configuration.uiOptions.applicationInsightsKey) {
                configureAi(configuration.uiOptions.applicationInsightsKey);
            } else {
                console.error('Application Insights is not configured.');
            }
        });
        // Initialize i18next.
        initialize().then((i) => {
            setI18nInstance(i);
        });
    }, []);

    if (i18nInstance == null) {
        return null;
    }

    return (
        <I18nextProvider i18n={i18nInstance}>
            <AppContainer>
                <Routes />
            </AppContainer>
        </I18nextProvider>
    );
};

export default withAITracking(reactPlugin, App, App.name, 'App-Container');
