import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { Store } from './store/Store';
import { DefaultFontStyle, Palette, DefaultComponentStyles, Fonts } from './theme/Theme';
import { ThemeProvider, PartialTheme } from '@fluentui/react/lib/Theme';
import { initializeIcons } from '@fluentui/react';
import App from './App';
import { IConfiguration } from './types/IConfiguration';

// Initialize the fluent ui icons.
initializeIcons();
// Build the fluent ui theme.
const theme: PartialTheme = {
    palette: Palette,
    defaultFontStyle: DefaultFontStyle,
    components: DefaultComponentStyles(Palette),
    fonts: Fonts,
};
// Get the root element.
const rootElement = document.getElementById('root');
// Fetch the options from the frontend server.
export const _config: Promise<IConfiguration> = fetch('api/Configuration').then((response) => response.json());
// Wrap the application with a store provider and render the application at the root element.
ReactDOM.render(
    <StoreProvider store={Store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </StoreProvider>,
    rootElement
);
